
import { defineComponent, onErrorCaptured, ref } from "vue";
import ProfileNavigationMenu from "@/app/private/pages/profile/components/ProfileNavigationMenu.vue";
import SpinnerLoader from "@/app/core/components/SpinnerLoader.vue";

export default defineComponent({
  name: "ProfileLayout",
  components: { SpinnerLoader, ProfileNavigationMenu },
  setup() {
    const error = ref<Error | null>(null);
    onErrorCaptured((e) => {
      error.value = e;
      return true;
    });
    return { error };
  },
});
