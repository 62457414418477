import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07ced26a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-wrapper grid-container" }
const _hoisted_2 = { class: "col-xs-2 col-sm-12" }
const _hoisted_3 = { class: "main col-xs-8 col-sm-12" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_navigation_menu = _resolveComponent("profile-navigation-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_spinner_loader = _resolveComponent("spinner-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_profile_navigation_menu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Error happened"))
        : (_openBlock(), _createBlock(_Suspense, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            fallback: _withCtx(() => [
              _createVNode(_component_spinner_loader, { visible: true })
            ]),
            _: 1
          }))
    ])
  ]))
}